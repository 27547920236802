import { Injectable } from "@angular/core";
import { GrafanaConfiguration } from "./grafana-configuration.model";
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class GrafanaConfigurationService {
    public getConfiguration() : GrafanaConfiguration {
        return environment['grafana'] ?? GrafanaConfiguration.defaultConfiguration;
    }
}