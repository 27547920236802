<div appBsModal #userDelegationsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" (onShown)="onShown()"
     aria-hidden="true" [config]="{keyboard: false}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>{{"UserDelegations" | localize}}</span>
                </h4>
                <button class="btn btn-primary pull-right" (click)="manageUserDelegations()"><i class="fa fa-plus"></i>{{"DelegateNewUser" | localize}}</button>
            </div>
            <div class="modal-body">
                <!--<Primeng-TurboTable-Start>-->
                <div class="primeng-datatable-container"
                     [busyIf]="primengTableHelper.isLoading">
                    <p-table #dataTable
                             sortMode="multiple"
                             (onLazyLoad)="getUserDelegations($event)"
                             [value]="primengTableHelper.records"
                             rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                             [paginator]="false"
                             [lazy]="false"
                             [responsive]="primengTableHelper.isResponsive">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:30%" pSortableColumn="userName">
                                    {{'UserName' | localize}}
                                    <p-sortIcon field="userName"></p-sortIcon>
                                </th>
                                <th style="width:20%">
                                    {{'StartTime' | localize}}
                                </th>
                                <th style="width:20%">
                                    {{'EndTime' | localize}}
                                </th>
                                <th style="width:10%">
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit">
                            <tr>
                                <td style="width: 30%">
                                    <span class="p-column-title"> {{'UserName' | localize}}</span>
                                    {{record.username}}
                                </td>
                                <td style="width: 20%">
                                    <span class="p-column-title"> {{'StartTime' | localize}}</span>
                                    {{record.startTime | luxonFormat:'F	'}}
                                </td>
                                <td style="width: 20%">
                                    <span class="p-column-title"> {{'EndTime' | localize}}</span>
                                    {{record.endTime | luxonFormat:'F	'}}
                                </td>
                                <td style="width:10%">
                                    <button class="btn btn-outline-danger btn-sm btn-icon" (click)="deleteUserDelegation(record)">
                                        <i class="fa fa-trash" [attr.aria-label]="l('Delete')"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{'NoData' | localize}}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                     #paginator
                                     (onPageChange)="getUserDelegations($event)"
                                     [totalRecords]="primengTableHelper.totalRecordsCount"
                                     [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                     [showCurrentPageReport]="true"
                                     [currentPageReportTemplate]="'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount">
                        </p-paginator>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light-primary font-weight-bold pull-right" (click)="close()">{{"Close" | localize}}</button>
            </div>
        </div>
    </div>
</div>
<createNewUserDelegation #createNewUserDelegation (modalSave)="getUserDelegations()"></createNewUserDelegation>
