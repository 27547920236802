import { AppConsts } from "@shared/AppConsts";
import { environment } from "environments/environment";
import { ApplicationInsightsConfiguration } from "./application-insights-configuration.model";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsConfigurationService {
    public getConfiguration() : ApplicationInsightsConfiguration {
        const environmentConfigurationSection = environment[AppConsts.ApplicationInsightsConfigurationName];
        return environmentConfigurationSection;
    }
}