import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Generic',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'MasterData',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'GroupTrials',
                                'Pages.GroupTrials',
                                'flaticon-more',
                                '/app/main/generic/groupTrials'
                            ),
                            new AppMenuItem(
                                'GroupTrials',
                                'Pages.Administration.GroupTrials',
                                'flaticon-more',
                                '/app/admin/generic/groupTrials'
                            ),
                            new AppMenuItem(
                                'TrialDesignTypeses',
                                'Pages.TrialDesignTypeses',
                                'flaticon-more',
                                '/app/main/generic/trialDesignTypeses'
                            ),
                            new AppMenuItem(
                                'TrialStatuses',
                                'Pages.TrialStatuses',
                                'flaticon-more',
                                '/app/main/generic/trialStatuses'
                            ),
                            new AppMenuItem(
                                'AdultObservationCodes',
                                'Pages.AdultObservationCodes',
                                'flaticon-more',
                                '/app/main/generic/adultObservationCodes'
                            ),
                            new AppMenuItem(
                                'YoungObservationCodes',
                                'Pages.YoungObservationCodes',
                                'flaticon-more',
                                '/app/main/generic/youngObservationCodes'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'TrialRDs', 
                        'Pages.TrialRDs', 
                        'flaticon-more', 
                        '/app/main/generic/trialRDs'
                    ),
                ]
            ),
            new AppMenuItem(
                'Genetics',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'MasterData',
                        '',
                        'flaticon-interface-8',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'MasterDataTrials',
                                '',
                                'flaticon-settings',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'TypeTrials',
                                        'Pages.TypeTrials',
                                        'flaticon-more',
                                        '/app/main/generic/typeTrials'
                                    ),
                                    new AppMenuItem(
                                        'CategoryTrials',
                                        'Pages.CategoryTrials',
                                        'flaticon-more',
                                        '/app/main/generic/categoryTrials'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'MasterDataSpecies',
                                '',
                                'flaticon-settings',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'SpeciesGroups',
                                        'Pages.SpeciesGroups',
                                        'flaticon-more',
                                        '/app/main/genetics/speciesGroups'
                                    ),
                                    new AppMenuItem(
                                        'TypeSpecies',
                                        'Pages.TypeSpecies',
                                        'flaticon-more',
                                        '/app/main/genetics/typeSpecies'
                                    ),
                                    new AppMenuItem(
                                        'TypeGeneticMaterials',
                                        'Pages.TypeGeneticMaterials',
                                        'flaticon-more',
                                        '/app/main/genetics/typeGeneticMaterials'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'MasterDataSeeds',
                                '',
                                'flaticon-settings',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'CategorySeedOrigins',
                                        'Pages.CategorySeedOrigins',
                                        'flaticon-more',
                                        '/app/main/genetics/categorySeedOrigins'
                                    ),
                                    new AppMenuItem(
                                        'SeedDestinations',
                                        'Pages.SeedDestinations',
                                        'flaticon-more',
                                        '/app/main/genetics/seedDestinations'
                                    ),
                                    new AppMenuItem(
                                        'CrossingTechniques',
                                        'Pages.CrossingTechniques',
                                        'flaticon-more',
                                        '/app/main/genetics/crossingTechniques'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'MasterDataClones',
                                '',
                                'flaticon-settings',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'CloneStatuses',
                                        'Pages.CloneStatuses',
                                        'flaticon-more',
                                        '/app/main/genetics/cloneStatuses'
                                    ),
                                    new AppMenuItem(
                                        'BBXDestinations',
                                        'Pages.BBXDestinations',
                                        'flaticon-more',
                                        '/app/main/genetics/bbxDestinations'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'MasterDataProjects',
                                '',
                                'flaticon-settings',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'WoodSampleTypes',
                                        'Pages.WoodSampleTypes',
                                        'flaticon-more',
                                        '/app/main/genetics/woodSampleTypes'
                                    ),
                                    new AppMenuItem(
                                        'LaboratoryProjectStatuses',
                                        'Pages.LaboratoryProjectStatuses',
                                        'flaticon-more',
                                        '/app/main/genetics/laboratoryProjectStatuses'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'MasterDataOthers',
                                '',
                                'flaticon-settings',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'Suppliers',
                                        'Pages.Suppliers',
                                        'flaticon-more',
                                        '/app/main/generic/suppliers'
                                    ),
                                    new AppMenuItem(
                                        'FrostTolerances',
                                        'Pages.FrostTolerances',
                                        'flaticon-more',
                                        '/app/main/genetics/frostTolerances'
                                    ),
                                    new AppMenuItem(
                                        'TreeStatuses',
                                        'Pages.TreeStatuses',
                                        'flaticon-more',
                                        '/app/main/genetics/treeStatuses'
                                    ),
                                ]
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'TrialManagement',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem('Trials', 'Pages.Trials', 'flaticon-more', '/app/main/generic/trials'),
                            new AppMenuItem(
                                'Treatments',
                                'Pages.Treatments',
                                'flaticon-more',
                                '/app/main/generic/treatments'
                            ),
                        ]
                    ),
                    new AppMenuItem('Seeds', 'Pages.Seeds', 'flaticon-more', '/app/main/genetics/seeds'),
                    new AppMenuItem('Polens', 'Pages.Polens', 'flaticon-more', '/app/main/genetics/polens'),
                    new AppMenuItem(
                        'ControlledCrossings',
                        'Pages.ControlledCrossings',
                        'flaticon-more',
                        '/app/main/genetics/controlledCrossings'
                    ),
                    new AppMenuItem(
                        'TreeManagements',
                        'Pages.TreeManagements',
                        'flaticon-more',
                        '/app/main/genetics/treeManagements'
                    ),

                    new AppMenuItem(
                        'CloneManagement',
                        '',
                        'flaticon-more',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Clonecandidate',
                                '',
                                'flaticon-more',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'ImportR1output',
                                        'Pages.Projects',
                                        'flaticon-more',
                                        '/app/main/genetics/projects'
                                    ),
                                    new AppMenuItem(
                                        'MassivePhenotyping',
                                        'Pages.MeasurementSelections',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementSelections/massivephenotyping'
                                    ),
                                    new AppMenuItem(
                                        'LaboratorySheetAserrin',
                                        'Pages.MeasurementSelections',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementSelections/laboratorySheet'
                                    ),
                                    new AppMenuItem(
                                        'ImportR2output',
                                        'Pages.Projects',
                                        'flaticon-more',
                                        '/app/main/genetics/projectsR2'
                                    ),
                                    new AppMenuItem(
                                        'MassiveClonaleSelection',
                                        'Pages.MeasurementSelections',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementSelections/massiveclonaleselection'
                                    ),
                                    new AppMenuItem(
                                        'LaboratorySheetDisk',
                                        'Pages.MeasurementSelections',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementSelections/laboratorySheetDisco'
                                    ),
                                    new AppMenuItem(
                                        'BBT',
                                        'Pages.MeasurementSelections',
                                        'flaticon-more',
                                        '/app/main/genetics/bbtMaker/bbtMaker'
                                    ),
                                    new AppMenuItem(
                                        'Clon',
                                        'Pages.Clones',
                                        'flaticon-more',
                                        '/app/main/genetics/clones/designateClone'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'EvaluacionClon',
                                '',
                                'flaticon-more',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'ImportR3output',
                                        'Pages.ProjectConfigurations',
                                        'flaticon-more',
                                        '/app/main/genetics/importR3output'
                                    ),
                                    new AppMenuItem(
                                        'MesurementCloneEvaluations',
                                        'Pages.MesurementCloneEvaluations',
                                        'flaticon-more',
                                        '/app/main/genetics/mesurementCloneEvaluations'
                                    ),
                                    new AppMenuItem(
                                        'PromedioLabBbxes',
                                        'Pages.PromedioLabBbxes',
                                        'flaticon-more',
                                        '/app/main/genetics/promedioLabBbxes'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'ImportedClones',
                                'Pages.Clones',
                                'flaticon-more',
                                '/app/main/genetics/ImportedClones'
                            ),
                            new AppMenuItem(
                                'Destino/Status del Clon',
                                'Pages.Clones',
                                'flaticon-more',
                                '/app/main/genetics/bbxManagement'
                            ),
                            new AppMenuItem(
                                'GenomicSelection',
                                '',
                                'flaticon-more',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'GenomicSelectionProject',
                                        'Pages.Projects',
                                        'flaticon-more',
                                        '/app/main/genetic/genomicSeleccions'
                                    ),
                                    new AppMenuItem(
                                        'GenotypingEvaluation',
                                        'Pages.MeasurementSelectionGenomics',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementSelectionGenomics'
                                    ),
                                    new AppMenuItem(
                                        'BBT',
                                        'Pages.MeasurementSelectionGenomics',
                                        'flaticon-more',
                                        '/app/main/genetics/bbtMakerGenomicSelection/bbtMakerGenomicSelection'
                                    ),
                                    new AppMenuItem(
                                        'Clon',
                                        'Pages.MeasurementSelectionGenomics',
                                        'flaticon-more',
                                        '/app/main/genetics/clones/designateCloneGenomicSelection'
                                    ),
                                ]
                            ),
                        ]
                    ),

                    new AppMenuItem(
                        'OtherProjects',
                        '',
                        'flaticon-more',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Projects',
                                'Pages.MeasurementSelectionOtherProjects',
                                'flaticon-more',
                                '/app/main/genetics/otherProjects'
                            ),
                            new AppMenuItem(
                                'Measurements',
                                'Pages.MeasurementSelectionOtherProjects',
                                'flaticon-more',
                                '/app/main/genetics/measurementSelectionOtherProjects'
                            ),
                        ]
                    ),

                    new AppMenuItem(
                        'MeasurementsManagement',
                        '',
                        'flaticon-more',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'MeasurementsPlaningManagement',
                                '',
                                'flaticon-more',
                                '',
                                [],
                                [
                                    new AppMenuItem(
                                        'MeasurementPlanings',
                                        'Pages.MeasurementPlanings',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementPlanings'
                                    ),
                                    new AppMenuItem(
                                        'MeasurementPlaningOptionses',
                                        'Pages.MeasurementPlaningOptionses',
                                        'flaticon-more',
                                        '/app/main/genetics/measurementPlaningOptionses'
                                    ),
                                ]
                            ),
                            new AppMenuItem(
                                'TreeMeasurements',
                                'Pages.TreeMeasurements',
                                'flaticon-more',
                                '/app/main/genetics/treeMeasurements'
                            ),
                            new AppMenuItem(
                                'TreeMeasurementAlerts',
                                'Pages.TreeMeasurementAlerts',
                                'flaticon-more',
                                '/app/main/genetics/treeMeasurementAlerts'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'Querys',
                        '',
                        'flaticon-more',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'CloneSelectionT1',
                                '',
                                'flaticon-more',
                                '/app/main/genetics/cloneSelectionT1'
                            ),
                            new AppMenuItem(
                                'ClonePedigree',
                                'Pages.Clones',
                                'flaticon-more',
                                '/app/main/genetics/clonePedigree'
                            ),
                            new AppMenuItem(
                                'SeedPedigree',
                                'Pages.Clones',
                                'flaticon-more',
                                '/app/main/genetics/seedPedigree'
                            ),
                            new AppMenuItem(
                                'SeedCloneDetails',
                                'Pages.Clones',
                                'flaticon-more',
                                '/app/main/genetics/seedCloneDetails'
                            ),
                        ]
                    ),
                ]
            ),
            new AppMenuItem(
                'PestDisease',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'MasterData',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'PestAndDiseases',
                                'Pages.PestAndDiseases',
                                'flaticon-more',
                                '/app/main/pestDisease/pestAndDiseases'
                            ),
                        ]
                    ),
                ]
            ),
            new AppMenuItem(
                'Silviculture',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'MasterData',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'ExperimentLocation',
                                'Pages.ExperimentLocations',
                                'flaticon-more',
                                '/app/main/silviculture/experimentLocations'
                            ),
                            new AppMenuItem(
                                'ExperimentType',
                                'Pages.ExperimentTypes',
                                'flaticon-more',
                                '/app/main/silviculture/experimentTypes'
                            ),
                            new AppMenuItem(
                                'ExperimentClasificationtwo',
                                'Pages.ExperimentClasificationstwo',
                                'flaticon-more',
                                '/app/main/silviculture/experimentClasificationstwo'
                            ),
                            new AppMenuItem(
                                'ExperimentSpecieQuantity',
                                'Pages.ExperimentSpecieQuantities',
                                'flaticon-more',
                                '/app/main/silviculture/experimentSpecieQuantities'
                            ),

                            new AppMenuItem(
                                'ExperimentCategory',
                                'Pages.ExperimentCategories',
                                'flaticon-more',
                                '/app/main/silviculture/experimentCategories'
                            ),
                            new AppMenuItem(
                                'ExperimentClassification',
                                'Pages.ExperimentClassifications',
                                'flaticon-more',
                                '/app/main/silviculture/experimentClassifications'
                            ),
                            new AppMenuItem(
                                'ExperimentSpecie',
                                'Pages.ExperimentSpecies',
                                'flaticon-more',
                                '/app/main/silviculture/experimentSpecies'
                            ),
                            new AppMenuItem(
                                'ExperimentStigma',
                                'Pages.ExperimentStigma',
                                'flaticon-more',
                                '/app/main/silviculture/experimentStigma'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'SilvicultureTrialManager',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'SilvicultureTrial',
                                'Pages.SilvicultureTrials',
                                'flaticon-more',
                                '/app/main/silviculture/silvicultureTrials'
                            ),
                        ]
                    )
                ]
            ),
            new AppMenuItem(
                'Silviculture',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'MasterData',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'ExperimentLocation',
                                'Pages.ExperimentLocations',
                                'flaticon-more',
                                '/app/main/silviculture/experimentLocations'
                            ),
                            new AppMenuItem(
                                'ExperimentType',
                                'Pages.ExperimentTypes',
                                'flaticon-more',
                                '/app/main/silviculture/experimentTypes'
                            ),
                            new AppMenuItem(
                                'ExperimentClasificationtwo',
                                'Pages.ExperimentClasificationstwo',
                                'flaticon-more',
                                '/app/main/silviculture/experimentClasificationstwo'
                            ),
                            new AppMenuItem(
                                'ExperimentSpecieQuantity',
                                'Pages.ExperimentSpecieQuantities',
                                'flaticon-more',
                                '/app/main/silviculture/experimentSpecieQuantities'
                            ),

                            new AppMenuItem(
                                'ExperimentCategory',
                                'Pages.ExperimentCategories',
                                'flaticon-more',
                                '/app/main/silviculture/experimentCategories'
                            ),
                            new AppMenuItem(
                                'ExperimentClassification',
                                'Pages.ExperimentClassifications',
                                'flaticon-more',
                                '/app/main/silviculture/experimentClassifications'
                            ),
                            new AppMenuItem(
                                'ExperimentSpecie',
                                'Pages.ExperimentSpecies',
                                'flaticon-more',
                                '/app/main/silviculture/experimentSpecies'
                            ),
                            new AppMenuItem(
                                'ExperimentStigma',
                                'Pages.ExperimentStigma',
                                'flaticon-more',
                                '/app/main/silviculture/experimentStigma'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'SilvicultureTrialManager',
                        '',
                        'flaticon-settings',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'SilvicultureTrial',
                                'Pages.SilvicultureTrials',
                                'flaticon-more',
                                '/app/main/silviculture/silvicultureTrials'
                            ),
                        ]
                    ),
                ]
            ),
            new AppMenuItem(
                'Nursery',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'Querys',
                        '',
                        'flaticon-more',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'RootingPendings',
                                'Pages.PorcentajeBBXs',
                                'flaticon-more',
                                '/app/main/genetics/rootingPendings'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'SourcePlant',
                        '',
                        'flaticon-more',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'SourcePlants',
                                'Pages.SourcePlants',
                                'flaticon-more',
                                '/app/main/nursery/sourcePlants'
                            ),

                            new AppMenuItem(
                                'SourcePlantation',
                                'Pages.SourcePlantPlantations',
                                'flaticon-more',
                                '/app/main/nursery/sourcePlantPlantations'
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        'TrayRooting',
                        'Pages.RoatingporcentajeTraies',
                        'flaticon-more',
                        '/app/main/nursery/roatingporcentajeTraies'
                    ),
                ]
            ),
            /*new AppMenuItem(
                'PlanningTreesToMeasures',
                'Pages.PlanningTreesToMeasures',
                'flaticon-more',
                '/app/main/genetics/planningTreesToMeasures'
            ),*/

            new AppMenuItem(
                'Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'flaticon-refresh',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'flaticon2-world',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    ),
                ]
            ),
            
            
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
