import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { faro } from '@grafana/faro-web-sdk';
import { GrafanaConfigurationService } from '@app/shared/observability/grafana-configuration.service';
import { ApplicationInsightsConfigurationService } from '@app/shared/observability/application-insights-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {
    private appInsights: ApplicationInsights;

    constructor(
        private readonly _grafanaConfigurationService: GrafanaConfigurationService,
        private readonly _applicationInsightsConfigurationService: ApplicationInsightsConfigurationService,
    ) {
        this.startMonitoring();
    }

    private startMonitoring(): void {
        const applicationInsightsConfiguration = this._applicationInsightsConfigurationService.getConfiguration();
        if (applicationInsightsConfiguration.isEnabled) {
            this.appInsights = new ApplicationInsights({
                config: {
                instrumentationKey: applicationInsightsConfiguration.instrumentationKey,
                enableAutoRouteTracking: true
            }});
    
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView();
    
            const telemetryInitializer = (envelope) => {
                envelope.tags['ai.cloud.role'] = applicationInsightsConfiguration.cloudRoleName;
            };
    
            this.appInsights.addTelemetryInitializer(telemetryInitializer);
        }
    }
        
    logEvent(name: string, properties?: { [key: string]: any }): void {
        if (this.isApplicationInsightsEnabled()) {
            this.appInsights.trackEvent({ name: name }, properties);
        }
        if (this.isGrafanaEnabled()) {
            faro.api.pushEvent(name);
        }
    }
    
    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        if (this.isApplicationInsightsEnabled()) {
            this.appInsights.trackMetric({ name: name, average: average }, properties);
        }
        if (this.isGrafanaEnabled()) {
            faro.api.pushMeasurement({
                type: name,
                values: {
                  duration: average,
                },
            });
        }
    }
    
    logException(exception: Error, severityLevel?: number): void {
        if (this.isApplicationInsightsEnabled()) {
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        }
        console.error(exception);
        if (this.isGrafanaEnabled()) {
            faro.api.pushError(exception);
        }
    }
    
    logTrace(message: string, properties?: { [key: string]: any }): void {
        if (this.isApplicationInsightsEnabled()) {
            this.appInsights.trackTrace({ message: message }, properties);
        }
        if (this.isGrafanaEnabled()) {
            faro.api.pushLog([message]);
        }
    }

    private isGrafanaEnabled(): boolean {
        return this._grafanaConfigurationService.getConfiguration().isEnabled;
    }

    private isApplicationInsightsEnabled() : boolean {
        return this._applicationInsightsConfigurationService.getConfiguration().isEnabled;
    }
}