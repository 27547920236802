export class GrafanaConfiguration {
    constructor(
        public isEnabled: boolean,
        public url: string,
        public environmentName: string
    ) {
        
    }

    static readonly defaultConfiguration: GrafanaConfiguration = {
        isEnabled: false,
        url: '',
        environmentName: ''
    };
}