import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { LinkAccountModalComponent } from '@app/shared/layout/link-account-modal.component';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { UserDelegationsModalComponent } from '@app/shared/layout/user-delegations-modal.component';
import { CreateNewUserDelegationModalComponent } from '@app/shared/layout/create-new-user-delegation-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { SmsVerificationModalComponent } from '@app/shared/layout/profile/sms-verification-modal.component';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ImpersonationService } from './admin/users/impersonation.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './shared/layout/themes/default/default-layout.component';
import { Theme5LayoutComponent } from './shared/layout/themes/theme5/theme5-layout.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
import { ThemeSelectionPanelComponent } from './shared/layout/theme-selection/theme-selection-panel.component';
import { ChatFriendListItemComponent } from './shared/layout/chat/chat-friend-list-item.component';
import { ChatMessageComponent } from './shared/layout/chat/chat-message.component';
import { FooterComponent } from './shared/layout/footer.component';
import { LinkedAccountService } from './shared/layout/linked-account.service';
import { SideBarMenuComponent } from './shared/layout/nav/side-bar-menu.component';
import { TopBarMenuComponent } from './shared/layout/nav/top-bar-menu.component';
import { QuickThemeSelectionComponent } from './shared/layout/topbar/quick-theme-selection.component';
import { LanguageSwitchDropdownComponent } from './shared/layout/topbar/language-switch-dropdown.component';
import { ChatToggleButtonComponent } from './shared/layout/topbar/chat-toggle-button.component';
import { SubscriptionNotificationBarComponent } from './shared/layout/topbar/subscription-notification-bar.component';
import { UserMenuComponent } from './shared/layout/topbar/user-menu.component';
import { DefaultBrandComponent } from './shared/layout/themes/default/default-brand.component';
import { Theme5BrandComponent } from './shared/layout/themes/theme5/theme5-brand.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { HeaderNotificationsComponent } from './shared/layout/notifications/header-notifications.component';
import { NotificationSettingsModalComponent } from './shared/layout/notifications/notification-settings-modal.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ActiveDelegatedUsersComboComponent } from './shared/layout/topbar/active-delegated-users-combo.component';

import { IMaskModule } from 'angular-imask';
// Metronic
import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';
import { SessionTimeoutModalComponent } from './shared/common/session-timeout/session-timeout-modal-component';
import { SessionTimeoutComponent } from './shared/common/session-timeout/session-timeout.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MenuSearchBarComponent } from './shared/layout/nav/menu-search-bar/menu-search-bar.component';
import { NgxSpinnerModule, NgxSpinnerComponent } from 'ngx-spinner';
import { ScrollTopComponent } from './shared/layout/scroll-top.component';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { SubheaderModule } from './shared/common/sub-header/subheader.module';
import Dexie from 'dexie';
import { AdultObservationCodeDto, FrostToleranceDto, GetMeasurementPlaningOptionsForViewDto, GetTreatmentForPWAViewDto, PestAndDiseaseDto, TreeToMeasureDto, YoungObservationCodeDto } from '@shared/service-proxies/service-proxies';
import { DowloadCache } from '@shared/System/DowloadCache';
import { ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken } from 'ngx-connection-service';
import { ChangeProfilePictureModalComponent } from './shared/layout/profile/change-profile-picture-modal.component';
import { CoreModule } from '@metronic/app/core/core.module';
import { truncate } from 'fs';
import { DndDirective } from './dnd.directive';

//DEXIE DB
@Injectable({
    providedIn: 'root'
})
export class DataRD extends Dexie {
    cacheDownload: Dexie.Table<DowloadCache, number>;
    treesToMeasure: Dexie.Table<TreeToMeasureDto, number>;
    measurementPlaningOptions: Dexie.Table<GetMeasurementPlaningOptionsForViewDto, number>;
    treatments: Dexie.Table<GetTreatmentForPWAViewDto, number>;
    adultObservationCodes: Dexie.Table<AdultObservationCodeDto, number>;
    youngObservationCodes: Dexie.Table<YoungObservationCodeDto, number>;
    frostToleranceCodes: Dexie.Table<FrostToleranceDto, number>;
    pestAndDiseaseCode: Dexie.Table<PestAndDiseaseDto, number>;
    constructor() {
        super('DataRD');
        this.version(1).stores({
            treesToMeasure: '&[id+measurementPlaningOptionsId], id, measurementPlaningOptionsId, order',
            measurementPlaningOptions: '&id',
            cacheDownload: "&id++,TrialID,Name,path",
            treatments: '&id, measurementPlaningOptionId',
            adultObservationCodes: '&id, code, detail',
            youngObservationCodes: '&id, code, detail',
            frostToleranceCodes: '&id, code, name',
            pestAndDiseaseCode: '&id, code, name',
        });
    }
}

export const db = new DataRD();
//END DEXIE DB

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        Theme5LayoutComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        ScrollTopComponent,
        LinkedAccountsModalComponent,
        UserDelegationsModalComponent,
        CreateNewUserDelegationModalComponent,
        LinkAccountModalComponent,
        ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        MySettingsModalComponent,
        SmsVerificationModalComponent,
        NotificationsComponent,
        ChatBarComponent,
        ThemeSelectionPanelComponent,
        ChatFriendListItemComponent,
        NotificationSettingsModalComponent,
        ChatMessageComponent,
        QuickThemeSelectionComponent,
        LanguageSwitchDropdownComponent,
        ChatToggleButtonComponent,
        SubscriptionNotificationBarComponent,
        UserMenuComponent,
        DefaultBrandComponent,
        Theme5BrandComponent,
        SessionTimeoutModalComponent,
        SessionTimeoutComponent,
        MenuSearchBarComponent,
        ActiveDelegatedUsersComboComponent,
        DndDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FileUploadModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ProgressBarModule,
        PerfectScrollbarModule,
        CoreModule,
        IMaskModule,
        ImageCropperComponent,
        AutoCompleteModule,
        NgxSpinnerModule,
        AppBsModalModule,
        SubheaderModule,
        ConnectionServiceModule
    ],
    providers: [
        {
            provide: ConnectionServiceOptionsToken,
            useValue: <ConnectionServiceOptions>{
                enableHeartbeat: true,
                heartbeatUrl: '/assets/ping.json',
                requestMethod: 'get',
                heartbeatInterval: 5000,
                heartbeatRetryInterval: 5000
            }
        },
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        DataRD,
    ]
})
export class AppModule { }
