import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.TrialRDsServiceProxy,        
        ApiServiceProxies.SilvicultureTrialsServiceProxy,        
        ApiServiceProxies.SilvicultureTrialsFilesServiceProxy,
        ApiServiceProxies.ExperimentLocationsServiceProxy,        
        ApiServiceProxies.ExperimentTypesServiceProxy,        
        ApiServiceProxies.ExperimentClasificationstwoServiceProxy,        
        ApiServiceProxies.ExperimentSpeciesServiceProxy,        
        ApiServiceProxies.ExperimentSpecieQuantitiesServiceProxy,        
        ApiServiceProxies.ExperimentClassificationsServiceProxy,        
        ApiServiceProxies.ExperimentCategoriesServiceProxy,
        ApiServiceProxies.ExperimentStigmaServiceProxy,        
        ApiServiceProxies.MeasurementSelectionOtherProjectsServiceProxy,        
        ApiServiceProxies.MeasurementSelectionGenomicsServiceProxy,        
        ApiServiceProxies.GenomicSeleccionsServiceProxy,        
        ApiServiceProxies.TreeMeasurementAlertsServiceProxy,        
        ApiServiceProxies.ParametersServiceProxy,        
        ApiServiceProxies.PlanningTreesToMeasuresServiceProxy,        
        ApiServiceProxies.MeasurementPlaningTreeHeightNumbersServiceProxy,        
        ApiServiceProxies.MeasurementPlaningTreatmentsServiceProxy,        
        ApiServiceProxies.MeasurementPlaningTreeNumbersServiceProxy,        
        ApiServiceProxies.MeasurementPlaningsServiceProxy,        
        ApiServiceProxies.MeasurementPlaningOptionsesServiceProxy,        
        ApiServiceProxies.RootingPendingsServiceProxy,
        ApiServiceProxies.TreeManagementsServiceProxy,        
        ApiServiceProxies.RoatingporcentajeTraiesServiceProxy,        
        ApiServiceProxies.SourcePlantPlantationsServiceProxy,        
        ApiServiceProxies.SourcePlantsServiceProxy,        
        ApiServiceProxies.ProjectConfigurationsServiceProxy,        
        ApiServiceProxies.MesurementCloneEvaluationsServiceProxy,        
        ApiServiceProxies.PromedioLabBbxesServiceProxy,        
        ApiServiceProxies.PorcentajeBBXsServiceProxy,        
        ApiServiceProxies.PedigreeServiceProxy,
        ApiServiceProxies.DestinoBBXsServiceProxy,        
        ApiServiceProxies.ClonesServiceProxy,        
        ApiServiceProxies.BBXManagementServiceProxy,         
        ApiServiceProxies.SeedMixturesServiceProxy,        
        ApiServiceProxies.BBXDestinationsServiceProxy,        
        ApiServiceProxies.LaboratoryProjectStatusesServiceProxy,        
        ApiServiceProxies.MeasurementSelectionsServiceProxy,        
        ApiServiceProxies.ProjectsServiceProxy,        
        ApiServiceProxies.ControlledCrossingsServiceProxy,        
        ApiServiceProxies.TreeMeasurementsServiceProxy,        
        ApiServiceProxies.TreesServiceProxy,        
        ApiServiceProxies.TreatmentsServiceProxy,        
        ApiServiceProxies.HelperSeedsServiceProxy,        
        ApiServiceProxies.SeedsServiceProxy,        
        ApiServiceProxies.HelperPolensServiceProxy,        
        ApiServiceProxies.PolenMixturesServiceProxy,        
        ApiServiceProxies.PolensServiceProxy,        
        ApiServiceProxies.TrialsServiceProxy,        
        ApiServiceProxies.TrialDesignTypesesServiceProxy,        
        ApiServiceProxies.TrialStatusesServiceProxy,        
        ApiServiceProxies.CategoryTrialsServiceProxy,        
        ApiServiceProxies.WoodSampleTypesServiceProxy,        
        ApiServiceProxies.TypeSpeciesServiceProxy,        
        ApiServiceProxies.TypeGeneticMaterialsServiceProxy,        
        ApiServiceProxies.CrossingTechniquesServiceProxy,        
        ApiServiceProxies.YoungObservationCodesServiceProxy,        
        ApiServiceProxies.AdultObservationCodesServiceProxy,        
        ApiServiceProxies.SeedDestinationsServiceProxy,        
        ApiServiceProxies.LaboratoryProjectsServiceProxy,        
        ApiServiceProxies.TreeStatusesServiceProxy,        
        ApiServiceProxies.PestAndDiseasesServiceProxy,        
        ApiServiceProxies.CloneStatusesServiceProxy,        
        ApiServiceProxies.TypeTrialsServiceProxy,        
        ApiServiceProxies.FrostTolerancesServiceProxy,        
        ApiServiceProxies.CategorySeedOriginsServiceProxy,        
        ApiServiceProxies.SuppliersServiceProxy,        
        ApiServiceProxies.GroupTrialsServiceProxy,        
        ApiServiceProxies.SpeciesGroupsServiceProxy,                                
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.LookUpCloneServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
