export const environment = {
    production: false,
    hmr: false,
    appConfig: './config/appconfig.staging.json',
    ApplicationInsights: {
        isEnabled: true,
        instrumentationKey: '49397885-52d2-40db-8d8e-7add10d2e31d',
        cloudRoleName: "Research Development Frontend"
    },
    grafana: {
        isEnabled: true,
        url: 'https://faro-collector-prod-sa-east-1.grafana.net/collect/4d0d1f1a67c08787389d07b2ba1f4f7a',
        environmentName: 'staging',
    }
};
