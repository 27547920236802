import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { AppConsts } from '../../../shared/AppConsts';
import { GrafanaConfiguration } from './grafana-configuration.model';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

export function appInitializeFaro(grafanaConfiguration: GrafanaConfiguration): void {
    const isEnabled: boolean = grafanaConfiguration.isEnabled;
    if (!isEnabled) {
        return;
    }
    
    const versionNumber = AppConsts.WebAppGuiVersion;
    const environmentName = grafanaConfiguration.environmentName;
    const grafanaUrl = grafanaConfiguration.url;
    const appNameForGrafana = AppConsts.GrafanaApplicationName;

    initializeFaro({
        url: grafanaUrl,
        app: {
            name: appNameForGrafana,
            version: versionNumber,
            environment: environmentName,
        },
        instrumentations: [
            ...getWebInstrumentations({ captureConsole: true, captureConsoleDisabledLevels: [] }),
            new TracingInstrumentation(),
        ],
    });
}

