import { IThemeAssetContributor } from '@app/shared/layout/themes/ThemeAssetContributor';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';
import { DefaultThemeAssetContributor } from '@app/shared/layout/themes/default/DefaultThemeAssetContributor';
import { Theme5ThemeAssetContributor } from '@app/shared/layout/themes/theme5/Theme5ThemeAssetContributor';


export class ThemeAssetContributorFactory {
    static getCurrent(): IThemeAssetContributor {
        let theme = ThemeHelper.getTheme();

        if (theme === 'default') {
            return new DefaultThemeAssetContributor();
        }

        if (theme === 'theme5') {
            return new Theme5ThemeAssetContributor();
        }
        return null;
    }
}
