<div dropdown class="dropdown dropdown-language" aria-haspopup="true" *ngIf="languages.length > 1" [dropup]="isDropup">
    <div dropdownToggle class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false">
        <div [class]="customStyle">
            <i [class]="currentLanguage.icon"></i>
        </div>
    </div>
    <div *dropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right" [attr.aria-label]="l('Languages')">
        <div perfectScrollbar>
            <ul class="navi navi-hover py-4">
                <li class="navi-item" *ngFor="let language of languages">
                    <a href="javascript:;" (click)="changeLanguage(language.name)" class="navi-link">
                        <span class="symbol symbol-20 mr-3">
                            <i [class]="language.icon"></i>
                        </span>
                        <span class="navi-text">
                            {{language.displayName}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
